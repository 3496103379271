import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "overflow-y-auto p-0 w-full py-12 xs:py-24" }
const _hoisted_2 = { class: "bg-pureblack-10 m-auto mb-0 w-352 shadow-1" }
const _hoisted_3 = { class: "text-center pb-32 pt-32 px-16 text-center" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _renderSlot(_ctx.$slots, "footer")
    ]),
    _renderSlot(_ctx.$slots, "privacy-policy")
  ]))
}